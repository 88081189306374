import experiments from '../experiments.json'

const ul = document.querySelector('ul.experiments')
ul.textContent = ''

experiments.forEach(({ name, desc, url }) => {
  const li = document.createElement('li')
  li.classList.add('experiment')

  const a = document.createElement('a')
  a.textContent = name
  a.setAttribute('href', url)
  a.setAttribute('title', desc)
  a.setAttribute('target', '_blank')

  li.append(a)
  ul.append(li)
})
